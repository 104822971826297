<!--
 * @Author: wyq
 * @Date: 2021-09-23 10:28:34
 * @LastEditTime: 2022-04-15 10:20:44
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\adaptiveTesting\index.vue
-->
<template>
  <el-container class="adaptive-testing">
    <el-header>
      <public-header :leftArrow="false">自适应测试</public-header>
    </el-header>
    <loadMore
      ref="more"
      @query="queryList"
      :list.sync="list"
      :defaultPageSize="1000"
      defaultHeight="calc(100vh - 77px)"
      path="adaptiveTesting"
    >
      <el-main>
        <div class="card-container" v-for="(item,index) in list" :key="index">
          <v-card class="rounded-xl" @click="goDetail(item.topic.id,item.topic.label)">
            <color-title color="accent" :title="item.topic.label"></color-title>
            <div class="score">
              <p v-if="item.records">{{item.records[item.records.length - 1].score}}</p>
              <p v-else>--</p>
              <p>近期能力评估</p>
            </div>
            <linemap
              v-if="item.records"
              path="adaptiveTesting"
              :idNumber="''+ item.topic.id"
              :mapData="item.records"
              class="echart-container"
            />
            <div v-else class="noDataTips">暂无数据</div>
          </v-card>
        </div>
      </el-main>
    </loadMore>
  </el-container>
</template>
<script>
import linemap from '@/components/echarts/linemap'
import loadMore from '@/components/loadMore/loadMore'

export default {
  components: {
    linemap,
    loadMore,
  },
  data() {
    return {
      list: [],
    }
  },
  methods: {
    queryList() {
      this.$service.getTopicList().then((res) => {
        // const topicList = this.$store.state.topicList
        let result = res.list
        // for (let i of topicList) {
        //   result.push(res.list[i])
        // }
        // 节选
        this.$refs.more.complete(result)
      })
    },
    goDetail(id, name) {
      this.$router.push({
        name: 'AdaptiveTestingDetail',
        query: {
          id: id,
          name: name,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.adaptive-testing {
  .el-main {
    margin-left: -32px;
  }
  .card-container {
    width: calc(50% - 32px);
    margin-left: 32px;
    margin-bottom: 32px;
    display: inline-block;
    height: 210px;
  }
  .v-card {
    padding: 24px 32px;
    .label {
      font-size: 20px;
    }
    .score {
      display: inline-block;
      width: 120px;
      p:first-of-type {
        font-size: 56px;
        color: #333;
        margin-bottom: 14px;
        margin-top: 24px;
        text-align: center;
        line-height: 68px;
        font-family: Akrobat-Black;
      }
      p:nth-of-type(2) {
        font-size: 18px;
        color: #999;
        margin-bottom: 0;
        text-align: center;
        font-family: PingFangSC-Regular;
      }
    }
    .echart-container {
      float: right;
    }
    .noDataTips {
      float: right;
      color: #d1d1d1;
      font-size: 30px;
      margin: 40px 30px 0 0;
    }
  }
}
</style>